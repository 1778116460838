<template>
  <main>
    
    <div class="intro-container">
      <div class="intro">
        <div class="intro-flex">
          <div class="me">
            <img src="https://media.discordapp.net/attachments/1014883899723223111/1041078368768106616/avatar4.gif?height=80&width=80" alt="" style="width:80px;">
            <div class="flame-profile" style="margin-left: 5px">
              <h1>Anime Flame#6666</h1>
             <!-- <div class="badge">Developer</div> -->
            </div>
          </div>
          <!-- <div class="player">
            <div class="control">
              <i class="fa-solid fa-backward-step" id="prev"></i>
              <i class="fa-solid fa-stop" id="play"></i>
              <i class="fa-solid fa-forward-step" id="next"></i>
            </div>
          </div> -->
           <div class="links">
            <img src='https://cdn.discordapp.com/emojis/1011512404250726490.webp?size=60&quality=lossless' alt="" class="link"/>
            <img src='https://cdn.discordapp.com/emojis/972917028493078559.webp?size=60&quality=lossless' alt="" class="link"/>
            <img src='https://media.discordapp.net/attachments/952983464339189852/1041483734374953010/72b09178fb2ab4bf.png?width=60&height=60' alt="" class="link"/>
            <img src='https://media.discordapp.net/attachments/952983464339189852/1041483274037497947/160b3a47acceb3af.png?width=60&height=60' alt="" class="link"/>
          </div>
        </div>
        <div class="big-buttons">
          <!-- https://discord.com/channels/@me/246646936713691136 -->
          <a href="https://discord.com/users/246646936713691136" class="bigbtn-container">
            Связь со мной
          </a>
           <a href="/paint" class="bigbtn-container">
            Рисовалка
          </a>
        </div>
        <div class="about-me">
            <h2 class="aboutme-title">Обо мне</h2>
            <div class="aboutme-text"><p> Меня зовут Рома, делаю дискорд ботов на заказ. Пишу на JavaScript, использую базу данных MongoDB.</p></div>
            <h2 class="findme-title"><a href="https://findteam.cc" style="font-size: 30px">FindTeam</a></h2>
            <div class="findme-text"><p>FindTeam — Это сервис для поиска напарников по самым разным играм, ищешь напарников в доту? кс? или может геншин? заходи к нам, и найди их!<br><a href="https://findteam.cc" style="font-size: 18px">https://findteam.cc</a></p></div>
            <!-- <div class="aboutme-text">Упс...</div> -->
        </div>
        <div class="price">
          <h2 class="store-title">Заказ бота</h2>
          <div class="price-attention"><b>Внимание!</b> Все цены указаны с учетом стандартной комплектации по мнению автора. За подробностями по каждой категории обращайтесь в лс, скорее всего итоговая цена окажется ниже. Также возможно написание кода, ботов на заказ.</div>
          <!-- <hr class=""> -->
          <div class="price-container" id="counter">
            <div class="price-block">
              <div class="price-title">Экономика</div>
              <div class="price-value">5 000₽</div>
            </div>
            <div class="price-block">
              <div class="price-title">Ивенты</div>
              <div class="price-value">4 500₽</div>
            </div>
             <div class="price-block">
              <div class="price-title">Кланы</div>
              <div class="price-value">4 000₽</div>
            </div>
            <div class="price-block">
              <div class="price-title">Анти-Краш</div>
              <div class="price-value">3 000₽</div>
            </div>
            <div class="price-block">
              <div class="price-title">Модерация (кнопки)</div>
              <div class="price-value">3 000₽</div>
            </div>
            <div class="price-block">
              <div class="price-title">Музыка</div>
              <div class="price-value">2 000₽</div>
            </div>
            <div class="price-block">
              <div class="price-title">Личные комнаты</div>
              <div class="price-value">2 000₽</div>
            </div>
            <div class="price-block">
             <div class="price-title">Приватные комнаты</div>
             <div class="price-value">2 000₽</div>
           </div>
             <div class="price-block">
              <div class="price-title">Модерация контента<br>(Система Редакторов)</div>
              <div class="price-value">1 500₽</div>
            </div>
            <div class="price-block">
              <div class="price-title">Жалобы</div>
              <div class="price-value">1 500₽</div>
            </div>
            <div class="price-block">
              <div class="price-title">Модерация</div>
              <div class="price-value">2 000₽</div>
            </div>
            <div class="price-block">
              <div class="price-title">Система свадеб</div>
              <div class="price-value">1 000₽</div>
            </div>
            <div class="price-block">
              <div class="price-title">Верификация</div>
              <div class="price-value">1 000₽</div>
            </div>
            <div class="price-block">
              <div class="price-title">Навигация+Игровые роли+Роли оповещений</div>
              <div class="price-value">1 000₽</div>
            </div>
            <div class="price-block">
              <div class="price-title">Баннер сервера (аним)</div>
              <div class="price-value">1000₽</div>
            </div>
            <div class="price-block">
              <div class="price-title">Система уровня</div>
              <div class="price-value">500₽</div>
            </div>
            <div class="price-block">
              <div class="price-title">Наборы</div>
              <div class="price-value">500₽</div>
            </div>
            <div class="price-block">
              <div class="price-title">Баннер сервера (статик)</div>
              <div class="price-value">500₽</div>
            </div>
            <div class="price-block">
              <div class="price-title">Социальные действия<br>(няшности)</div>
              <div class="price-value">500₽</div>
            </div>
          </div>
        </div>
        <div class="projects">
          <h2 class="projects-title">Проекты</h2>
          <div class="projects-container">
            <div class="projects-card valorant">
              <i class="fa-sharp fa-solid fa-star star"></i>
                <a href="https://discord.gg/valorantru">
                  <img src="https://cdn.discordapp.com/icons/681925602747351080/6333cb54ebc6ffc8b043cc9346059119.webp?size=80" alt="" class="project-icon">
                    <a href="https://discord.gg/valorantru" class="valorant-name">VALORANT СНГ</a>
                </a>
            </div>
            <div class="projects-card hatory">
              <i class="fa-sharp fa-solid fa-star star"></i>
                <a href="https://discord.gg/hatory">
                  <img src="https://cdn.discordapp.com/icons/894663985650081864/a_3986597b2591042b545b8b665ba90998.webp?size=80" alt="" class="project-icon">
                  <a href="https://discord.gg/hatory" class="project-name">HATORY</a>
                </a>
            </div>
            <div class="projects-card hatory">
                <a href="https://discord.gg/rastafari">
                  <img src="https://cdn.discordapp.com/icons/660183032333860864/a_2026856f3ac9a9358ac8a40ad485814b.gif?size=80" alt="" class="project-icon">
                  <a href="https://discord.gg/rastafari" class="project-name">Rastafari</a>
                </a>
            </div>
            <div class="projects-card">
              <a href="https://discord.gg/zxcursed">
                <img src="https://cdn.discordapp.com/icons/796861735016726539/a_41a1132e653188f56ffdc54dbd73607a.gif?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/zxcursed" class="project-name">zxcursed</a>
              </a>
             </div>
            <!-- <div class="projects-card">
              <a href="https://discord.gg/stockholm">
                <img src="https://cdn.discordapp.com/icons/935695524831567972/3ba3b6445e83c037102003d845042d2e.webp?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/stockholm" class="project-name">Stockholm</a>
              </a>
            </div> -->
            <div class="projects-card">
              <a href="https://discord.gg/nevermore">
                <img src="https://cdn.discordapp.com/icons/1000834852066697307/a_bf820bd6fb177c693e7579dd9352665f.gif?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/nevermore" class="project-name">NEVERMORE</a>
              </a>
            </div>
            <div class="projects-card">
              <a href="https://discord.gg/4rain">
                <img src="https://cdn.discordapp.com/icons/1007716878577315880/a_095c9ae9b922a7eededf18ea0cd91679.gif?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/4rain" class="project-name">4 rain</a>
              </a>
            </div>
            <div class="projects-card">
              <a href="https://discord.gg/robzi">
                <img src="	https://cdn.discordapp.com/icons/427115188085456899/a_000763dcdc316262db3cd5780474f436.webp?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/robzi" class="project-name">Robzi</a>
              </a>
            </div>
            <div class="projects-card">
              <a href="https://discord.gg/jesusavgn">
                <img src="https://cdn.discordapp.com/icons/986784113040191589/99c2bb79cb4fa7c95144279dbb706862.webp?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/jesusavgn" class="project-name">JesusAVGN</a>
              </a>
            </div>
            <div class="projects-card">
              <a href="https://discord.gg/neverend">
                <img src="https://cdn.discordapp.com/icons/1037386074051330150/2e5204c284d54ca812e717463050435a.webp?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/neverend" class="project-name">NEVEREND</a>
              </a>
            </div>
            <div class="projects-card">
              <a href="https://discord.gg/yummmy">
                <img src="https://cdn.discordapp.com/icons/1016356843045535844/a_8e5158f9ca530d2a7837f922ddd78154.gif?size=96" alt="" class="project-icon">
                <a href="https://discord.gg/yummmy" class="project-name">Yummy</a>
              </a>
            </div>
            <div class="projects-card shiza">
              <a href="https://discord.gg/minori">
                <img src="https://cdn.discordapp.com/icons/1039204169720025120/a_80645278cc85dfbde18af79f2ccef320.gif?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/minori" class="project-name">Minori</a>
              </a>
            </div>
              <div class="projects-card">
              <a href="https://discord.gg/slavamarlow">
                <img src="https://cdn.discordapp.com/icons/983705153540620328/eab09458250e56a6e84534fefce5fce6.webp?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/slavamarlow" class="project-name">Slava Marlow</a>
              </a>
            </div>
            <div class="projects-card">
              <a href="https://discord.gg/pixie">
                <img src="https://cdn.discordapp.com/icons/826446674595151872/a_ead6620c6a9597bbde4bd51b0439959e.webp?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/pixie" class="project-name">Pixie</a>
              </a>
            </div>
             <div class="projects-card">
              <a href="https://discord.gg/npl">
                <img src="https://cdn.discordapp.com/icons/952639736638504960/a_60099fa3b1f60cc479e73c5cfd6ae7c6.gif?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/npl" class="project-name">NPL</a>
              </a>
            </div>
            <div class="projects-card">
              <a href="https://discord.gg/decides">
                <img src="https://cdn.discordapp.com/icons/1044632380549378170/a_0a8510fcdb206f466517d7b12505e09b.gif?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/decides" class="project-name">DECIDE</a>
              </a>
            </div>
          </div>
        </div>

        <!-- <div class="projects">
          <h2 class="">Fame/Media</h2>
          <div class="projects-container">
            <div class="projects-card">
              <img src="https://cdn.discordapp.com/icons/681925602747351080/bb0e3997f43e461e9c3b1ad4a551bc4e.webp?size=80" alt="" class="project-icon">
              <a href="https://discord.gg/valorantru" class="project-name">VALORANT СНГ</a>
            </div>
          </div>
        </div> -->

        <div class="fames">
          <h2 class="fames-title">Fame/Media</h2>
          <div class="fames-container">
            <div class="fames-card">
              <a href="https://discord.gg/sattaki">
                <img src="https://cdn.discordapp.com/icons/798867454205231124/a_b56fd5910764acc15245ee7bf391c829.gif?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/sattaki" class="project-name">Sattaki 🎄</a>
              </a>
            </div>
            <div class="fames-card">
              <a href="https://discord.gg/cramberry">
                <img src="https://cdn.discordapp.com/icons/993979889332006913/a_f70e0481b2d043a6298a42c0a03b6f36.gif?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/cramberry" class="project-name">Cramberry</a>
              </a>
            </div>
            <div class="fames-card">
              <a href="https://discord.gg/hakone">
                <img src="https://cdn.discordapp.com/icons/784061217173667901/a_1e8417d70686734f24670abdb5928777.gif?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/hakone" class="project-name">Hakone</a>
              </a>
            </div>
          </div>
        </div>

        <div class="partners">
          <h2 class="pretners-title">Наши сотрудничества</h2>
          <div class="partners-container">
            <div class="partners-card">
              <img src="https://media.discordapp.net/attachments/1014883899723223111/1014884063892488213/riot_games.png?height=80&width=80" alt="" class="project-icon">
              <a href="https://www.riotgames.com/en" class="project-name">Riot Games</a>
            </div>
            <div class="partners-card">
              <img src="https://media.discordapp.net/attachments/1014883899723223111/1014887206869016606/nevermore.jpg?height=80&width=80" alt="" class="project-icon">
              <a href="https://www.twitch.tv/razedoto" class="project-name">Shadowraze</a>
            </div>
            <div class="partners-card">
              <img src="https://media.discordapp.net/attachments/1014883899723223111/1014887570494193734/zxcursed.png?height=80&width=80" alt="" class="project-icon">
              <a href="https://open.spotify.com/artist/53fwP60M1QNPDiXALVMeOs?si=SWGCpXA2Sc6Fb7k_RSuYRA" class="project-name">zxcursed</a>
            </div>
            <div class="partners-card">
              <img src="https://media.discordapp.net/attachments/952983464339189852/1041075148142428160/unknown.png?height=80&width=80" alt="" class="project-icon">
              <a href="https://open.spotify.com/artist/0XFgyr4jwM0MGeZZW0VzA5?si=iAzrHT4BSLWF0xp3qT_hiQ" class="project-name">DVRST</a>
            </div>
            <div class="partners-card">
              <img src="https://cdn.discordapp.com/attachments/952983464339189852/1041076520912949349/unknown-2-2.png" alt="" class="project-icon">
              <a href="https://open.spotify.com/artist/3zgn9mSaWsqdpcKo94Z5oi?si=QWfmKIZmTaSRKlePILA3iQ" class="project-name">Rizza</a>
            </div>
             <div class="partners-card">
              <img src="https://cdn.discordapp.com/attachments/952983464339189852/1041076659295617064/unknown-3-2.png" alt="" class="project-icon">
              <a href="https://open.spotify.com/artist/2IGZnq5Ot1NquCpks3zO4o?si=C3BteptQRrmVyeD02wkjDw" class="project-name">Sqwore</a>
            </div>
            <div class="partners-card">
              <img src="https://cdn.discordapp.com/icons/986784113040191589/99c2bb79cb4fa7c95144279dbb706862.webp?size=80" alt="" class="project-icon">
              <a href="https://www.twitch.tv/jesusavgn" class="project-name">JesusAVGN</a>
            </div>
            <div class="partners-card">
              <img src="https://cdn.discordapp.com/attachments/952983464339189852/1041077403079942235/unknown-4-2.png" alt="" class="project-icon">
              <a href="https://open.spotify.com/artist/55jryyk7RhvMbrvoF0ndBh?si=yvpcJ7uFRVqPeQ21p4xxsA" class="project-name">Slava Marlow</a>
            </div>
            <div class="partners-card">
              <img src="https://media.discordapp.net/attachments/952983464339189852/1041077956254113903/unknown.png?width=80&height=80" alt="" class="project-icon">
              <a href="https://open.spotify.com/artist/496nklFjflGjJOhhfhH2Nc?si=yCzS7RmxSearRVry8uTTQw" class="project-name">17 SEVENTEEN</a>
            </div>
          </div>
        </div>


          <div class="projects">
          <h2 class="friends-title">Друзья</h2>
          <div class="projects-container">
            <div class="team-card">
              <img src="https://cdn.discordapp.com/avatars/354261484395560961/a_59aeef3df7e81cc4fea15d06034a3fa2.gif?size=80" alt="" class="project-icon">
              <a href="https://jerrysmith.store" class="project-name">Jerry Smith#6666</a>
            </div>
            <div class="team-card">
              <img src="https://cdn.discordapp.com/avatars/301218562146566146/9e6c82861984c4ac47659bced45a9b82.webp?size=80" alt="" class="project-icon">
              <a href="https://discord.com/users/301218562146566146" class="project-name">zargovv#6666</a>
            </div>
            <div class="team-card">
              <img src="https://cdn.discordapp.com/avatars/343379630365081600/a2fa68ae6656a4e0b2ce57046d658fe6.webp?size=80" alt="" class="project-icon">
              <a href="https://discord.com/users/343379630365081600" class="project-name">ar1starX#2458</a>
            </div>
            <div class="team-card">
              <img src="https://cdn.discordapp.com/avatars/287934700230541312/a_3cb28e9362723717cd83f38d281d4cdf.gif?size=80" alt="" class="project-icon">
              <a href="https://discord.com/users/993644554689454122" class="project-name">Andrew Dark#9249</a>
            </div>
            <div class="team-card">
              <img src="https://cdn.discordapp.com/avatars/375274737103798282/67860c4ff5c0605c56e7d89bc22c4764.webp?size=80" alt="" class="project-icon">
              <a href="https://discord.com/users/375274737103798282" class="project-name">Palermo#6361</a>
            </div>
            <div class="team-card">
              <img src="https://cdn.discordapp.com/avatars/771534095242362921/3f4215b718a5cf2ccb136a6a07a058b9.webp?size=80" alt="" class="project-icon">
              <a href="https://discord.com/users/771534095242362921" class="project-name">profileqq#7672</a>
            </div>
            <div class="team-card">
              <img src="https://cdn.discordapp.com/avatars/919338267042779176/86a8b10caeb1c897a7109dd2770a1377.webp?size=80" alt="" class="project-icon">
              <a href="https://discord.com/users/919338267042779176" class="project-name">obessed w myself#7777</a>
              <!-- <h6>Bot Developer</h6> -->
            </div>
          </div>
        </div>
        
        <div class="stats">
          <h2 class="stats-title">Статистика</h2>
          <div class="stats-container" id="counter">
            <div class="stats-block">
              <div class="stats-number" data-num="700000">700000</div>
              <div class="stats-value">Пользователей</div>
            </div>
            <div class="stats-block">
              <div class="stats-number">6935</div>
              <div class="stats-value">Бессонных ночей</div>
            </div>
            <div class="stats-block">
              <div class="stats-number" data-num="600">4</div>
              <div class="stats-value">Года работы</div>
            </div>
          </div>
        </div>
        <div class="made">Made by <span class="badge">Jerry Smith && Anime Flame</span></div>
      </div>
      
    </div>
  </main>
</template>

<script>
require('../js/main.js');
//require('../js/player.js')
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 @import '../css/main';
</style>