<template>
  <MainPage/>
</template>

<script>
import MainPage from './components/MainPage.vue'


export default {
  name: 'App',
  components: {
    MainPage
}
}
</script>

<style>

:root {
  --bg-gradient: linear-gradient(to right, #aa4b6b, #6b6b83, #3b8d99);
}
@font-face {
  font-family: 'Hauora Regular';
  src: url(../src/assets/fonts/Hauora-Regular.ttf);
}

@font-face {
  font-family: 'Hauora Medium';
  src: url(../src/assets/fonts/Hauora-Medium.ttf);
}

@font-face {
  font-family: 'Hauora Bold';
  src: url(../src/assets/fonts/Hauora-Bold.ttf);
}



body {
  /* background: linear-gradient(to right, #7b4397, #dc2430); */
  font-family: 'Hauora Bold', 'Arial';
  background: rgb(3, 3, 3);
  background: linear-gradient(0deg, rgba(0,0,0,1) 8%, rgb(20 1 46));
  color: rgb(221, 221, 221);
  padding: 0;
  margin: 0;
}

a, button {
  display: block;
  color: rgb(221, 221, 221);
  font-size: 15px;
  text-decoration: none;
  outline: 0;
  border: 0;
  background: 0;
  cursor: pointer;
}

::-webkit-scrollbar {
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: rgb(177, 58, 58);
  border: 5px solid #000;
  border-radius: 1rem;
}
</style>
