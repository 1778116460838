import $ from 'jquery';  
export default {
  name: 'MainPage',
  methods: {
    t(value) {
      console.log(value)
    }
  }
}
function spaceDigits(number) {
    return number.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
}
let cc = 1
$(window).scroll(function() {
  $('#counter').each(function() {
    var
      cPos = $(this).offset().top,
      topWindow = $(window).scrollTop();
    if (cPos >= topWindow + 150) {
      if (cc < 2) {
      $('.stats-number').each(function() {
        $(this).prop('Counter', 0).animate({
            Counter: $(this).text()
        }, {
            duration: 3000,
            easing: 'swing',
            step: function(now) {
                $(this).text(spaceDigits(Math.ceil(now).toString()));
            }
        });
      });
      cc = cc+1
      }
    }
  });
});

var tracklist = [
  {
    src: 'https://cdn.discordapp.com/attachments/1026115246886223944/1042012298157498468/satori.mp3'
  },
  {
    src: 'https://cdn.discordapp.com/attachments/1026115246886223944/1042037518587154512/Jarry_-_pt._1.mp3'
  }
];

var player = false;
var track = 1;
var lastTrack = -1;

var play = function() {
  if (lastTrack != track) {
      player.src = tracklist[track - 1].src;
  }

  player.volume = 0.1
  lastTrack = track;

  if (player.paused) {
    player.play()
  } else {
    player.pause()
  }
};

$(document).ready(async function(){
  player = document.createElement('audio');


  let playbtn = $("#play")

    player.onplay = function () {
      playbtn.removeClass("fas-solid fa-stop");
      playbtn.addClass("fas-solid fa-pause");
    }

    player.onpause = function () {
      playbtn.addClass("fas-solid fa-stop");
      playbtn.removeClass("fas-solid fa-pause");
    }

    $('#control').click(function(event) {
        event.preventDefault();
    });

    $('#play').click(function() {
        play();
    });

    $('#next').click(function() {
        track++;

        console.log('next')

        if (track > tracklist.length) {
            track = 1;
        }

        play();
    });

    $('#prev').click(function() {
        track--;

        console.log('prev')

        if (track < 1) {
            track = tracklist.length;
        }

        play();
    });
})

  // var playpause = function () {
  //   if (player.paused) {
  //     player.volume = 0.1
  //     player.play();
  //   } else {
  //     player.pause();
  //   }
  // }

  // function nextTrack() {

  // }

  // playbtn.click(playpause)


  // $('#playbtn').click(function() {
  //   playpause()
  // })

  // player.onplay = function() {
  //   playbtn.classList.remove("fa-play");
  //   playbtn.classList.add("fa-pause");
  // }
  
  // playbtn.click(function() {
  //   player.play()
  // })

  // $(".player ").click(async function(){
  //   let player = $('.player')[0]

  //   console.log(this.id)

  //   console.log(this.className)

  //   if (this.id == 'playbtn') {
  //     if (player.paused) {
  //       player.play()
  //     } else {
  //       player.pause()
  //     }
  //   }
  // });


// var player = document.getElementById("musicplayer");
// // //let progress = document.getElementById("progress");
// //let playbtn = document.getElementById("playbtn");

// if (player) {
// player.play();
// }

// var playpause = function () {
//   if (player.paused) {
    
//   } else {
//     player.pause();
//   }
// }

// if (playbtn) {
//   playbtn.addEventListener("click", playpause);
// }
// player.onplay = function () {
//   playbtn.classList.remove("fa-play");
//   playbtn.classList.add("fa-pause");
// }

// player.onpause = function () {
//   playbtn.classList.add("fa-play");
//   playbtn.classList.remove("fa-pause");
// }

// // player.ontimeupdate = function () {
// //   let ct = player.currentTime;
// //   current.innerHTML = timeFormat(ct);
// //   //progress
// //   let duration = player.duration;
// //   let prog = Math.floor((ct * 100) / duration);
// //   progress.style.setProperty("--progress", prog + "%");
// // }

// // function timeFormat(ct) {
// //  var minutes = Math.floor(ct / 60);
// //  var seconds = Math.floor(ct % 60);

// //   if (seconds < 10) {
// //     seconds = "0"+seconds;
// //   }

// //   return minutes + ":" + seconds;
// // }